<template>
  <div class="footer bg-light">
    <h6 class = "header6">All content copyright © 2021 Harden Soul Fund</h6>
    <h6 class ="header6"><a href="https://www.linkedin.com/in/chris-harden/" target="_blank">Catch me on LinkedIn</a></h6>
  </div>
</template>

<script>
export default {
name: 'Footer',
components: {

}
}
</script>

<style>
.footer {
  width: 100%;
  margin-top: 150px;
  text-align: center;
  height: 150px;
  padding-top: 50px;
}

a,
a:active {
  color: blue;
}

a:hover,
a:active:hover {
  color: #8B7752;
}
</style>
